var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"help_block block_box"},[_c('div',{staticClass:"block_frame box_size"},[_c('div',{staticClass:"block_cephalo"},[_vm._m(0),_c('div',{staticClass:"block_cephalo_right"},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.infoObj.faq_data?.length !== 0),expression:"infoObj.faq_data?.length !== 0"}],staticClass:"gengduo",attrs:{"target":"_blank","to":{
            path: '/documentcenter',
            query: { onlyFlag: _vm.defaultIndex1 },
          }}},[_vm._v("查看更多")])],1)]),_c('div',{staticClass:"block_list"},_vm._l((_vm.infoObj.faq_data),function(item){return _c('div',{key:item.id,ref:"selectFiles",refInFor:true,staticClass:"block_li cursor_poin"},[_c('router-link',{attrs:{"target":"_blank","to":{
            path: '/documentcenter',
            query: { onlyFlag: item.onlyFlag },
          }}},[_vm._v(_vm._s(item.title))])],1)}),0)]),_c('div',{staticClass:"block_frame box_size"},[_c('div',{staticClass:"block_cephalo"},[_vm._m(1),_c('div',{staticClass:"block_cephalo_right"},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.infoObj.orientation_data?.length !== 0),expression:"infoObj.orientation_data?.length !== 0"}],staticClass:"gengduo",attrs:{"target":"_blank","to":{
            path: '/documentcenter',
            query: { onlyFlag: _vm.defaultIndex2 },
          }}},[_vm._v("查看更多")])],1)]),_c('div',{staticClass:"block_list"},_vm._l((_vm.infoObj.orientation_data),function(item){return _c('div',{key:item.id,staticClass:"block_li cursor_poin"},[_c('router-link',{attrs:{"target":"_blank","to":{
            path: '/documentcenter',
            query: { onlyFlag: item.onlyFlag },
          }}},[_vm._v(_vm._s(item.title))])],1)}),0)]),_c('div',{staticClass:"block_frame box_size"},[_c('div',{staticClass:"block_cephalo"},[_vm._m(2),_c('div',{staticClass:"block_cephalo_right"},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.infoObj.update_data?.length !== 0),expression:"infoObj.update_data?.length !== 0"}],staticClass:"gengduo",attrs:{"target":"_blank","to":{
            path: '/documentcenter',
            query: { onlyFlag: _vm.defaultIndex3 },
          }}},[_vm._v("查看更多")])],1)]),_c('div',{staticClass:"block_list"},_vm._l((_vm.infoObj.update_data),function(item){return _c('div',{key:item.id,staticClass:"block_li cursor_poin"},[_c('router-link',{attrs:{"target":"_blank","to":{
            path: '/documentcenter',
            query: { onlyFlag: item.onlyFlag },
          }}},[_vm._v(_vm._s(item.title))])],1)}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block_cephalo_left"},[_c('div',{staticClass:"block_cephalo_img"},[_c('img',{attrs:{"src":"/mp/icon/support_01.8791b76.png"}})]),_c('div',{staticClass:"block_cephalo_title"},[_vm._v("常见问题")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block_cephalo_left"},[_c('div',{staticClass:"block_cephalo_img"},[_c('img',{attrs:{"src":"/mp/icon/support_02.d5c55dc.png"}})]),_c('div',{staticClass:"block_cephalo_title"},[_vm._v("新人指引")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block_cephalo_left"},[_c('div',{staticClass:"block_cephalo_img"},[_c('img',{attrs:{"src":"/mp/icon/support_03.e9c7d0e.png"}})]),_c('div',{staticClass:"block_cephalo_title"},[_vm._v("更新动态")])])
}]

export { render, staticRenderFns }