<template>
  <div class="HelpCenterSearch">
    <div class="header_nav" :style="{backgroundImage: `url(${$StaticFileUrl + '/mp/pages/HelpCenter/bag_support_p.png'})` }">
      <div class="head_content">
        <div class="head_title">欢迎来到万鲸开放平台</div>
        <a-select
          show-search
          :value="value"
          placeholder="输入关键字进行搜索"
          style="width: 620px;"
          :default-active-first-option="false"
          :show-arrow="false"
          :not-found-content="fetching ? undefined : null"
          :filter-option="false"
          @search="fetchKeywords"
          @change="handleChange"
          size="large"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="d in data" :key="d.key" @click="clickOption">
            {{ d.text }}
          </a-select-option>
        </a-select>
      </div>
    </div>

  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/30 15:49
 * @version 1.0
 * @description
 */
import debounce from 'lodash.debounce'
import {helpCenterSearch} from '@/api/HelpCenter'
export default {
  name: 'HelpCenterSearch',
  data() {
    this.lastFetchId = 0
    return {
      data: [],
      fetchKeywords: debounce(this.fetchKeyWords, 800),
      value: undefined,
      fetching: false,
    }
  },
  methods: {
    clickOption() {
      console.log('c')
    },
    fetchKeyWords(value) {
      if (value.trim() === '') { this.data = [];return }
      console.log('fetching user', value)
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.data = []
      this.fetching = true
      helpCenterSearch(value).then(res => {
        console.log(res)
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }

        const data = res.data.map(user => ({
          key: user.onlyFlag,
          text: user.name,
          value: user.name,
        }))
        this.data = data
        this.fetching = false
      })
    },
    handleChange(value) {
      console.log(value, 'value')
      this.$router.push({path: 'documentcenter',query: {onlyFlag:value}})

    },
    deselect(value){
      console.log(value)
    }
  },

}
</script>

<style scoped lang="scss">
.header_nav{
  height: 288px;
  padding: 54px 0 0;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  text-align: center;
}
.head_content{
  width: 1280px;
  margin: 0 auto;
  text-align: left;
  .head_title{
    font-size: 32px;
    margin-bottom: 27px;
    font-weight: 400;
    color: #333;
  }
  .search {

  }
}
</style>
