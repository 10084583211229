<template>
  <div class="helpCenter">
    <HelpCenterSearch></HelpCenterSearch>
    <div class="help_nav">
      <ProblemComponents :infoObj="infoObj"></ProblemComponents>
      <treeIndex :treeData="treeData"></treeIndex>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/30 15:09
 * @version 1.0
 * @description
 */
import HelpCenterSearch from "@/views/HelpCenter/HelpCenterSearch";
import ProblemComponents from "@/views/HelpCenter/ProblemComponents";
import treeIndex from "@/views/HelpCenter/ProblemComponents/treeIndex";
import { inquireTree, getDetailsList } from "@/api/HelpCenter";

export default {
  name: "HelpCenter",
  components: { HelpCenterSearch, ProblemComponents, treeIndex },
  data() {
    return {
      faqData: [],
      orientationData: [],
      updateData: [],
      treeData: [],
      infoObj: {},
    };
  },
  created() {
    let _infoObj = {};
    let tree = inquireTree(8).then((res) => {
      let { code, data } = res;
      if (200 === code) {
        this.treeData = data;
        // this.$set(this.infoObj,'tree_data',data)
      }
    });
    let DetailList = getDetailsList(5).then((res) => {
      console.log(res, "帮助中心分页列表5");
      let { code, data } = res;
      if (200 === code) {
        let { rows, total } = data;
        this.faqData = rows;
        _infoObj["faq_data"] = rows;
      }
    });
    let DetailList2 = getDetailsList(6).then((res) => {
      console.log(res, "帮助中心分页列表6");
      let { code, data } = res;
      if (200 === code) {
        let { rows, total } = data;
        this.orientationData = rows;
        _infoObj["orientation_data"] = rows;
      }
    });
    let DetailList3 = getDetailsList(7).then((res) => {
      console.log(res, "帮助中心分页列表7");
      let { code, data } = res;
      if (200 === code) {
        let { rows, total } = data;
        this.updateData = rows;
        _infoObj["update_data"] = rows;
      }
    });

    Promise.all([DetailList3, DetailList2, DetailList, tree]).then(() => {
      this.infoObj = Object.freeze(_infoObj);
    });
  },
};
</script>

<style scoped lang="scss">
.help_nav {
  width: 1280px;
  margin: 0 auto;
  .block_box {
    display: grid;
    grid-column-gap: 32px;
    .block_frame {
      height: 230px;
      background: #fff;
      border-radius: 2px;
      padding: 17px 25px 22px;
      .block_cephalo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 18px;
        border-bottom: 1px solid #eee;
        .block_cephalo_left {
          display: flex;
          align-items: center;
          .block_cephalo_img {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .block_cephalo_title {
            font-size: 18px;
            font-weight: 700;
            color: #333;
            margin-left: 10px;
          }
        }
        .block_cephalo_right {
          font-size: 18px;
          font-weight: 400;
          color: #666;
          display: flex;
          align-items: center;
        }
      }
      .block_list {
        display: grid;
        .block_li {
          font-size: 18px;
          font-weight: 400;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          list-style: none;
          margin-top: 18px;
          padding-left: 12px;
          position: relative;
          cursor: pointer;
          &::before {
            width: 4px;
            height: 4px;
            content: "";
            border-radius: 50%;
            background-color: #666;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  .help_block {
    grid-template-columns: 584px 1fr 1fr;
    margin-bottom: 27px;
    .block_frame {
      box-shadow: 0 3px 10px 1px rgba(203, 208, 238, 0.3);
      transform: translateY(-37px);
      &:first-child {
        .block_list {
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 20px;
        }
      }
    }
  }
}
</style>
