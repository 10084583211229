var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom_wrap"},_vm._l((_vm.treeData),function(item){return _c('div',{key:item.id,staticClass:"help_content"},[_c('div',{staticClass:"block_box"},[_c('div',{staticClass:"block_frame box_size"},[_c('div',{staticClass:"block_cephalo"},[_c('div',{staticClass:"block_cephalo_left"},[_c('div',{staticClass:"block_cephalo_img"},[_c('img',{attrs:{"src":item.thumbnail}})]),_c('div',{staticClass:"block_cephalo_title"},[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"block_cephalo_right"},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(item.children?.length !== 0),expression:"item.children?.length !== 0"}],staticClass:"gengduo",attrs:{"target":"_blank","to":{
                path: '/documentcenter',
                query: { onlyFlag: item.onlyFlag },
              }}},[_vm._v("查看更多")])],1)]),_c('div',{staticClass:"block_list"},_vm._l((item.children),function(items){return (items.name !== '常见问题')?_c('div',{key:items.id,staticClass:"block_li cursor_poin"},[_c('router-link',{attrs:{"target":"_blank","to":{
                path: '/documentcenter',
                query: { onlyFlag: items.onlyFlag },
              }}},[_vm._v(_vm._s(items.name))])],1):_vm._e()}),0)])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }