<template>
  <div class="bottom_wrap">
    <div class="help_content" v-for="item in treeData" :key="item.id">
      <div class="block_box">
        <div class="block_frame box_size">
          <div class="block_cephalo">
            <div class="block_cephalo_left">
              <div class="block_cephalo_img"><img :src="item.thumbnail" /></div>
              <div class="block_cephalo_title">{{ item.name }}</div>
            </div>
            <div class="block_cephalo_right">
              <router-link
                class="gengduo"
                v-show="item.children?.length !== 0"
                target="_blank"
                :to="{
                  path: '/documentcenter',
                  query: { onlyFlag: item.onlyFlag },
                }"
                >查看更多</router-link
              >
            </div>
          </div>
          <div class="block_list">
            <div
              class="block_li cursor_poin"
              v-for="items in item.children"
              :key="items.id"
              v-if="items.name !== '常见问题'"
            >
              <router-link
                target="_blank"
                :to="{
                  path: '/documentcenter',
                  query: { onlyFlag: items.onlyFlag },
                }"
                >{{ items.name }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/30 15:49
 * @version 1.0
 * @description
 */

export default {
  name: "ProblemComponents",
  props: {
    treeData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.help_nav .bottom_wrap .help_content:nth-child(3n + 3) {
  margin-right: 0;
}
.help_nav .help_content .block_box {
  grid-row-gap: 32px;
}
.help_nav .help_content {
  margin-bottom: 64px;
  width: 32%;
  margin-right: 24px;
}
.help_nav .bottom_wrap {
  display: flex;
  flex-wrap: wrap;
}
.cursor_poin {
  cursor: pointer;
}
.help_nav .help_block .block_frame:first-child .block_list {
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}
.block_list {
  display: grid;
  .block_li {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    list-style: none;
    margin-top: 18px;
    padding-left: 12px;
    position: relative;
    &:before {
      width: 4px;
      height: 4px;
      content: "";
      border-radius: 50%;
      background-color: #666;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      a {
        display: block;
        color: #3389ff !important;
      }
    }
  }
}
.block_cephalo_right {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666;
  display: flex;
  align-items: center;
  .gengduo:hover {
    cursor: pointer;
    color: #3389ff;
  }
}
.block_cephalo_left {
  display: flex;
  align-items: center;
  .block_cephalo_img {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .block_cephalo_title {
    font-size: 18px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: 700;
    color: #333;
    margin-left: 10px;
  }
}
.block_cephalo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 18px;
  border-bottom: 1px solid #eee;
}
.help_nav .block_box .block_frame {
  height: 230px;
  background: #fff;
  border-radius: 2px;
  padding: 17px 25px 22px;
}
.help_nav .help_content .block_box .block_frame {
  border: 1px solid #eee;
}
.block_frame {
  height: 230px;
  background: #fff;
  border-radius: 2px;
  padding: 17px 25px 22px;
}
.help_block {
  grid-template-columns: 584px 1fr 1fr;
  margin-bottom: 27px;
}
</style>
