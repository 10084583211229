<template>
  <div class="help_block block_box">
    <div class="block_frame box_size">
      <div class="block_cephalo">
        <div class="block_cephalo_left">
          <div class="block_cephalo_img">
            <img src="/mp/icon/support_01.8791b76.png" />
          </div>
          <div class="block_cephalo_title">常见问题</div>
        </div>
        <div class="block_cephalo_right">
          <router-link
            class="gengduo"
            v-show="infoObj.faq_data?.length !== 0"
            target="_blank"
            :to="{
              path: '/documentcenter',
              query: { onlyFlag: defaultIndex1 },
            }"
            >查看更多</router-link
          >
        </div>
      </div>
      <div class="block_list">
        <div
          class="block_li cursor_poin"
          ref="selectFiles"
          v-for="item in infoObj.faq_data"
          :key="item.id"
        >
          <router-link
            target="_blank"
            :to="{
              path: '/documentcenter',
              query: { onlyFlag: item.onlyFlag },
            }"
            >{{ item.title }}</router-link
          >
        </div>
      </div>
    </div>
    <div class="block_frame box_size">
      <div class="block_cephalo">
        <div class="block_cephalo_left">
          <div class="block_cephalo_img">
            <img src="/mp/icon/support_02.d5c55dc.png" />
          </div>
          <div class="block_cephalo_title">新人指引</div>
        </div>
        <div class="block_cephalo_right">
          <router-link
            class="gengduo"
            v-show="infoObj.orientation_data?.length !== 0"
            target="_blank"
            :to="{
              path: '/documentcenter',
              query: { onlyFlag: defaultIndex2 },
            }"
            >查看更多</router-link
          >
        </div>
      </div>
      <div class="block_list">
        <div
          class="block_li cursor_poin"
          v-for="item in infoObj.orientation_data"
          :key="item.id"
        >
          <router-link
            target="_blank"
            :to="{
              path: '/documentcenter',
              query: { onlyFlag: item.onlyFlag },
            }"
            >{{ item.title }}</router-link
          >
        </div>
      </div>
    </div>
    <div class="block_frame box_size">
      <div class="block_cephalo">
        <div class="block_cephalo_left">
          <div class="block_cephalo_img">
            <img src="/mp/icon/support_03.e9c7d0e.png" />
          </div>
          <div class="block_cephalo_title">更新动态</div>
        </div>
        <div class="block_cephalo_right">
          <router-link
            class="gengduo"
            v-show="infoObj.update_data?.length !== 0"
            target="_blank"
            :to="{
              path: '/documentcenter',
              query: { onlyFlag: defaultIndex3 },
            }"
            >查看更多</router-link
          >
        </div>
      </div>
      <div class="block_list">
        <div
          class="block_li cursor_poin"
          v-for="item in infoObj.update_data"
          :key="item.id"
        >
          <router-link
            target="_blank"
            :to="{
              path: '/documentcenter',
              query: { onlyFlag: item.onlyFlag },
            }"
            >{{ item.title }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/30 15:49
 * @version 1.0
 * @description
 */

export default {
  name: "ProblemComponents",
  props: {
    infoObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultIndex1: 0,
      defaultIndex2: 0,
      defaultIndex3: 0,
    };
  },
  watch: {
    infoObj(newValue, oldValue) {
      if (Object.values(newValue).length) {
        let { faq_data, orientation_data, update_data } = newValue;
        this.defaultIndex1 = faq_data[0].onlyFlag;
        this.defaultIndex2 = orientation_data[0].onlyFlag;
        this.defaultIndex3 = orientation_data[0].onlyFlag;
      }
    },
  },
  created() {
    this.$forceUpdate();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.help_nav .help_block {
  grid-template-columns: 584px 1fr 1fr;
  margin-bottom: 27px;
}
.help_nav .block_box {
  display: grid;
  grid-column-gap: 32px;
}
.cursor_poin {
  cursor: pointer;
}
.help_nav .help_block .block_frame:first-child .block_list {
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}
.block_list {
  display: grid;
  .block_li {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    list-style: none;
    margin-top: 18px;
    padding-left: 12px;
    position: relative;

    &:before {
      width: 4px;
      height: 4px;
      content: "";
      border-radius: 50%;
      background-color: #666;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      a {
        display: block;
        color: #3389ff !important;
      }
    }
  }
}
.block_cephalo_right {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666;
  display: flex;
  align-items: center;
  .gengduo:hover {
    cursor: pointer;
    color: #3389ff;
  }
}
.block_cephalo_left {
  display: flex;
  align-items: center;
  .block_cephalo_img {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .block_cephalo_title {
    font-size: 18px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: 700;
    color: #333;
    margin-left: 10px;
  }
}
.block_cephalo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 18px;
  border-bottom: 1px solid #eee;
}
.help_nav .help_block .block_frame {
  box-shadow: 0 3px 10px 5px rgb(203 208 238 / 30%);
  transform: translateY(-37px);
}
.block_frame {
  height: 230px;
  background: #fff;
  border-radius: 2px;
  padding: 17px 25px 22px;
}
</style>
